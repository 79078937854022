
import { createRouter,createWebHashHistory } from "vue-router";
import AppShell from '@/pages/wrapper/AppShell.vue';
import cbIndex from '@/pages/cbIndex/cbIndex.vue';
import Homepage from '@/pages/homepage/Homepage.vue';
import Services from '@/pages/services/Services.vue';
import Login from '@/pages/login/Login.vue';
import Departments from '@/pages/departments/Departments.vue';
import Items from '@/pages/items/Items.vue';
import ItemDetails from '@/pages/itemdetails/ItemDetails.vue';
import Initialization from '@/pages/initialization/Initialization.vue';
import DescriptionTags from '@/pages/descriptiontags/DescriptionTags.vue';
import CorporatesHead from '@/pages/corporateshead/CorporatesHead.vue';
import Regions from '@/pages/regions/Regions.vue';
import Announcements from '@/pages/announcements/Announcements.vue';
import DryCleaner from '@/pages/drycleaners/DryCleaners.vue';
import Branches from '@/pages/branches/Branches.vue';
import PrinterSetups from '@/pages/printersetups/PrinterSetups.vue';
import ReceiptPrinters from '@/pages/receiptprinter/ReceiptPrinters.vue';
import StoreDryCleaners from '@/pages/storedrycleaner/StoreDryCleaners.vue';
import Survey from '@/pages/survey/Survey.vue';
import Coupon from '@/pages/coupons/Coupons.vue';
import Backroom from '@/pages/backroom/Backroom.vue';
import OnRack from '@/pages/backroom/OnRack.vue';
import StoreExpenses from '@/pages/storeexpenses/StoreExpenses.vue';
import OnProgress from '@/pages/backroom/OnProgress.vue';
import CorporateBranches from '@/pages/corporatebranches/CorporateBranches.vue';
import ProjectedSales from '@/pages/projectedsales/ProjectedSales.vue';
import UpChargeLists from '@/pages/upchargelists/UpChargeLists.vue';
import Associates from '@/pages/associates/Associates.vue';
import Customers from '@/pages/customers/Customers.vue';
import { useStore } from "@/store.ts";

import AppCheckoutShell from '@/pages/wrapper/AppCheckoutShell.vue';
import ScheduleAssistance from '@/pages/schedule/ScheduleAssistance.vue';
import HoursApproval from '@/pages/hoursApproval/HoursApproval.vue';
import Attendance from '@/pages/attendance/Attendance.vue';
import OnReady from '@/pages/backroom/OnReady.vue';
import Transactions from '@/pages/transaction/Transactions.vue';
import CashRegister from '@/pages/cashRegister/CashRegister.vue';
import ManagerCashRegister from '@/pages/cashRegister/ManagerCashRegister.vue';
import CheckIn from '@/pages/checkIn/CheckIn.vue';
import ScheduleList from '@/pages/schedulelist/ScheduleList.vue';
import Report from '@/pages/reports/Report.vue';
import AssociateReport from '@/pages/reports/AssociateReport.vue';
import DrycleanInvoice from '@/pages/reports/DrycleanInvoice.vue';
import PriceList from '@/pages/pricelist/PriceList.vue';
import CouponReport from '@/pages/reports/CouponReport.vue';
import CorporateReport from '@/pages/reports/CorporateReport.vue';
import ProductSalesReport from '@/pages/reports/ProductSalesReport.vue';
import SuperCreaseReport from '@/pages/reports/SuperCreaseReport.vue';
import CustomerRelatedReport from '@/pages/reports/CustomerRelatedReport.vue';
import CustomerCorporateTypeReport from '@/pages/reports/CustomerCorporateTypeReport.vue';
import CustomerReport from '@/pages/reports/CustomerReport.vue';
import ReferralReport from '@/pages/reports/ReferralReport.vue';
import RefundReport from '@/pages/reports/RefundReport.vue';
import BuybackReport from '@/pages/reports/BuybackReport.vue';
import TopPerformanceReport from '@/pages/reports/TopPerformanceReport.vue';
import ProductivityReport from '@/pages/reports/ProductivityReport.vue';
import WeeklyPaymentReport from '@/pages/reports/WeeklyPaymentReport.vue';
import TimeSheetReport from '@/pages/reports/TimeSheetReport.vue';
import SalesSummaryReport from '@/pages/reports/SalesSummaryReport.vue';
import ItemsReport from '@/pages/reports/ItemsReport.vue';
import TransactionReport from '@/pages/reports/TransactionReport.vue';
import StockReport from '@/pages/reports/StockReport.vue';
import DailySalesReport from '@/pages/reports/DailySalesReport.vue';
import ServiceSummaryReport from '@/pages/reports/ServiceSummaryReport.vue';
import CashSummaryReport from '@/pages/reports/CashSummaryReport.vue';
import TenderSummaryReport from '@/pages/reports/TenderSummaryReport.vue';
import FlashReport from '@/pages/reports/FlashReport.vue';

const ifAuthenticated = (_, __, next) => {
  const store = useStore();
  if (store.getters.isAuthenticated == '') next({ path: '/login' })
  else next()
}

const ifNotAuthenticated = (_, __, next) => {

  const store = useStore();
  if (store.getters.isAuthenticated == '') next()
  else next({ path: '/store/dashboard' })

}

const routes = [
  { path: '/', redirect: "/store/dashboard", beforeEnter: ifAuthenticated },
  { path: '/login', name: 'login', component: Login, beforeEnter: ifNotAuthenticated },
  { path: '/cb_index', name: 'cbIndex', component: cbIndex},
  { path: '/check-in', name: 'check-in', component: CheckIn, beforeEnter: ifAuthenticated},
  { path: '/update-receipt/:receiptID', name: 'update-receipt', component: CheckIn, beforeEnter: ifAuthenticated, props: true },
  {
    path: '/backroom',
    redirect: "/backroom/on-rack",
    name: 'backroom',
    component: Backroom,
    beforeEnter: ifAuthenticated,
    children: [
      { path: 'on-rack', name: 'on-rack', component: OnRack },
      { path: 'on-progress', name: 'on-progress', component: OnProgress },
      { path: 'on-ready', name: 'on-ready', component: OnReady },
    ]
  },
  {
    path: '/process',
    name: 'app_checkout_shell',
    component: AppCheckoutShell,
    beforeEnter: ifAuthenticated,
    children: [
      { path: 'transactions', name: 'transactions', component: Transactions },
    ]
  },
  {
    path: '/store',
    name: 'app_shell',
    component: AppShell,
    beforeEnter: ifAuthenticated,
    children: [
      { path: 'hours-approval', name: 'HoursApproval', component: HoursApproval },
      { path: 'schedule', name: 'schedule', component: ScheduleAssistance },
      { path: 'initialization', name: 'initialization', component: Initialization },
      { path: 'stores', name: 'stores', component: Branches },
      { path: 'services', name: 'services', component: Services },
      { path: 'dashboard', name: 'dashboard', component: Homepage },
      { path: 'departments', name: 'departments', component: Departments },
      { path: 'items', name: 'items', component: Items },
      { path: 'item-detail', name: 'ItemDetails', component: ItemDetails },
      { path: 'description-tag', name: 'DescriptionTags', component: DescriptionTags },
      { path: 'corporates-head', name: 'CorporatesHead', component: CorporatesHead },
      { path: 'regions', name: 'Regions', component: Regions },
      { path: 'dry-cleaner', name: 'DryCleaner', component: DryCleaner },
      { path: 'announcement', name: 'Announcements', component: Announcements },
      { path: 'store-dry-cleaner', name: 'StoreDryCleaners', component: StoreDryCleaners },
      { path: 'receipt-printer', name: 'ReceiptPrinters', component: ReceiptPrinters },
      { path: 'printer-setup', name: 'PrinterSetups', component: PrinterSetups },
      { path: 'survey', name: 'Survey', component: Survey },
      { path: 'coupon', name: 'Coupon', component: Coupon },
      { path: 'store-expense', name: 'StoreExpenses', component: StoreExpenses },
      { path: 'corporate-braches', name: 'CorporateBranches', component: CorporateBranches },
      { path: 'project-sales', name: 'ProjectSales', component: ProjectedSales },
      { path: 'up-charge-lists', name: 'UpChargeLists', component: UpChargeLists },
      { path: 'associates', name: 'Associates', component: Associates },
      { path: 'customers', name: 'Customers', component: Customers },
      { path: 'attendance', name: 'Attendance', component: Attendance },
      { path: 'cash-register', name: 'CashRegister', component: CashRegister },
      { path: 'manager-cash-register', name: 'ManagerCashRegister', component: ManagerCashRegister },
      { path: 'schedule-list', name: 'ScheduleList', component: ScheduleList },
      { path: 'report', name: 'Report', component: Report },
      { path: 'associate-report', name: 'AssociateReport', component: AssociateReport },
      { path: 'dry-clean-invoice-report', name: 'DrycleanInvoice', component: DrycleanInvoice },
      { path: 'price-list', name: 'PriceList', component: PriceList },
      { path: 'coupon-report', name: 'CouponReport', component: CouponReport },
      { path: 'corporate-report', name: 'CorporateReport', component: CorporateReport },
      { path: 'product-sales-report', name: 'ProductSalesReport', component: ProductSalesReport },
      { path: 'super-crease-report', name: 'SuperCreaseReport', component: SuperCreaseReport },
      { path: 'customer-related-report', name: 'CustomerRelatedReport', component: CustomerRelatedReport },
      { path: 'customer-corporate-type-report', name: 'CustomerCorporateTypeReport', component: CustomerCorporateTypeReport },
      { path: 'customer-report', name: 'CustomerReport', component: CustomerReport },
      { path: 'referral-report', name: 'ReferralReport', component: ReferralReport },
      { path: 'refund-report', name: 'RefundReport', component: RefundReport },
      { path: 'buyback-report', name: 'BuybackReport', component: BuybackReport },
      { path: 'top-performance-report', name: 'TopPerformanceReport', component: TopPerformanceReport },
      { path: 'productivity-report', name: 'ProductivityReport', component: ProductivityReport },
      { path: 'weekly-payment-report', name: 'WeeklyPaymentReport', component: WeeklyPaymentReport },
      { path: 'time-sheet-report', name: 'TimeSheetReport', component: TimeSheetReport },
      { path: 'items-report', name: 'ItemsReport', component: ItemsReport },
      { path: 'sales-summary-report', name: 'SalesSummaryReport', component: SalesSummaryReport },
      { path: 'transaction-report', name: 'TransactionReport', component: TransactionReport },
      { path: 'stock-report', name: 'StockReport', component: StockReport },
      { path: 'service-summary-report', name: 'ServiceSummaryReport', component: ServiceSummaryReport },
      { path: 'daily-sales-report', name: 'DailySalesReport', component: DailySalesReport },
      { path: 'cash-summary-report', name: 'CashSummaryReport', component: CashSummaryReport },
      { path: 'tender-summary-report', name: 'TenderSummaryReport', component: TenderSummaryReport },
      { path: 'flash-report', name: 'FlashReport', component: FlashReport },
    ]
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// const router = createRouter({
//   history: createWebHistory(''),
//   routes,
// });

export default router;